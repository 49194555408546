<template>
  <div id="main">
    <div id="left">
      <div class="meio">
        <img src="../assets/logo-clic.png" alt="Clic Tecnologia" class="img"> 
        <br><br>
        <p>
          Seja bem vindo ao portal da 
          <a href="https://cdi.clictecnologia.com.br/suporte" target="_blank" rel="noopener"> Clic Tecnologia</a>.
        </p>
        <h6><a href="https://www.clictecnologia.com.br" target="_blank" rel="noopener"> www.clictecnologia.com.br</a></h6>
      </div>
    </div>

    <div id="right">
      <div class="meio">
        <div v-if="!this.empresas">
          <div class="main">
            <p class="sign" align="center">Olá, Usuário!</p>
            <center>
              <form class="filtro" @submit.prevent="validar()">
                <label for="inputLogin" class="labelLogin">Login:</label>
                <b-form-input
                  ref="login"
                  label="Usuário"
                  name="login"
                  type="text"
                  v-model="login"
                  size="sm"
                  id="inputLogin"
                  class="inputLogin"></b-form-input>
                
                <br><br>

                <label for="inputSenha" class="labelLogin">Senha:</label>
                <b-form-input
                  ref="senha"
                  label="Senha"
                  name="senha"
                  type="password"
                  v-model="senha"
                  size="sm"
                  id="inputSenha"
                  class="inputSenha"></b-form-input>                  

                <b-button
                  type="submit"
                  variant="primary"
                  size="sm"
                  class="btnProcessar">Entrar</b-button>
              </form>   
            </center>
          </div>          
        </div>
        <div v-else>
          <Processando v-if="isLoading"/>
          <div class="empresas" v-else>
            <div v-if="empresas.length > 0">
              <h4>Selecione a Empresa</h4>
              <a v-for="item in empresas" :key="item.id" :href="`/home`" @click="registraKey(item.token)"><div class="empresa">{{item.idCliente}}. {{item.nome}}</div></a>
            </div>
            <div v-else>Nenhuma empresa encontrada.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {autenticaUsuario} from '../common/services/usuarioService';
import Processando from '../components/comuns/Processando'
import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import { getUserObj,setUserObj } from "@/common/localStorage/localStorage";

export default {
  data() {
    return {
      empresas: null,
      login: null,
      senha: null,
      usuario: null,
      isLoading: true         
    }
  },
  components: {
    Processando,
    InputBasico,
    InputSelect
  },
  async created() {
    let obj = getUserObj();
    if (obj.usuario) {
      this.empresas = obj.usuario.empresas;
    }
    if (obj) {
      this.$router.push('/home')
    }
  },
  methods: {
    async validar() {
      let usuario = (await autenticaUsuario(this.login, this.senha)).data;
      if (usuario) {
        this.usuario = usuario;
        this.empresas = usuario.empresas;
        this.isLoading = false;
      }
    },
    registraKey(token) {
      if (token) {
        let obj = {
          token,
          usuario: this.usuario
        };

        setUserObj(obj);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/common/scss/breakpoints.scss";
  @import "@/common/scss/colors.scss";

  #main {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background-color: $cor-fundo-escura;
  }

  #right {
    float: right;
    width: 50%;
    height: 100%;
    padding: 60px;
    
    @media (max-width: $large-size) {
      float: none;
      padding: 10px;
      width: auto;
      height: auto;
    }    
  }

  #right .meio {
    margin-top: 15%;

    @media (max-width: $large-size) {
      margin-top: 10%;
    }    
  }

  #left {
    float: left;
    width: 50%;
    height: 100%; 	
    padding: 30px;
    color: $cor-fonte-clara;
    box-shadow: 3px 0px 8px rgba(68, 68, 68, 0.6);
    background-color: #1C1C1C;
    background-image: url("../assets/bg-laranja.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    
    @media (max-width: $large-size) {
      float: none;
      padding: 10px;
      width: auto;
      height: auto;
    }
  }

  #left a {
    color: $cor-fonte-clara;
  }

  .img {
    width: 8rem;
    height: auto;
    
    @media (min-width: $medium-size) {
      width: 15rem;
    }
  }

  #left .meio {
    position: relative;
    text-align: center;
    top: 20%;
  }

  .empresas {
    width: 100%;
    text-align: justify;
    color: #4F4F4F
  }
  
  .empresas a {
    color: #4F4F4F;
    text-decoration: none;
  }

  .empresa {
    font-weight: bold;
    background: #c0c0c0;
    border-radius: 0px 10px 0px 10px;
    margin: 10px 10px 0 0;
    padding: 10px;
  }

  .empresa:hover {
    background: 	#FFA500
  }

  .main {
    // background-color: #FFFFFF;
    width: 400px;
    height: 400px;
    margin: 7em auto;
    border-radius: 1.5em;
    // box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
  }
  
  .sign {
    padding-top: 10px;
    color: #6E6E6E;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
  }
        
  .filtro {
    display: grid;
    grid-template-columns: 1fr;
    text-align: left;
    @media (min-width: $medium-size) {
      width: 80%;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: 0.8em;
    }
  }

  .input {
    &Login {
      border: none;
      background: #D1D1D1;
      border-radius: none;
      border-bottom: 3px solid #6E6E6E;
      @media (min-width: $medium-size) {
        grid-column: 1 / 10;
      }
    }

    &Senha {
      border: none;
      background: #D1D1D1;
      border-radius: none;
      border-bottom: 3px solid #6E6E6E;
      @media (min-width: $medium-size) {
        grid-column: 1 / 10;
      }
    }
  }

  .labelLogin {
    font-weight: bold;
    color: #6E6E6E;
  }

  .btn {
    &Processar {
      background: #FFA500;
      border: none;
      @media (min-width: $medium-size) {
        grid-column: 1 / 10;
        margin-top: 1.0rem;
        margin-bottom: 1.9rem;
      }
    }
  }
</style>